import React, { Component } from "react";
import "../style/success.scss";
import HeaderBar from "./common/HeaderBar";
import FooterBar from "./common/FooterBar";
import SuccessIcon from "../icon/SuccessIcon";
import CloseIcon from "../icon/CloseIcon";
import queryString from "query-string";
import axios from "axios";

class Success extends Component {
  constructor(props) {
    super(props);
    this.state = {
      num: 0,
    };
    this.routeHome = this.routeHome.bind(this);
  }

  routeHome() {
    const { history } = this.props;
    history.push("/");
  }

  render() {
    return (
      <React.Fragment>
        <HeaderBar {...this.props} />
        <div className="pay-content">
          <div className="box">
            <CloseIcon size={20} className="close" onClick={this.routeHome} />
            <SuccessIcon size={30} />
            <p>Success</p>
            <div className="view">OK</div>
          </div>
        </div>
        <FooterBar {...this.props} />
      </React.Fragment>
    );
  }
}

export default Success;
