import React, { Component } from "react";
import "../../style/common/footerBar.scss";

class FooterBar extends Component {
  constructor(props) {
    super(props);
    this.routePage = this.routePage.bind(this);
  }

  routePage(type) {
    let { history } = this.props;
    switch (type) {
      case "home":
        history.push("/");
        break;
      case "about":
        history.push("/About");
        break;
      case "ship":
        history.push("/Ship");
        break;
      case "refund":
        history.push("/Refund");
        break;
      case "service":
        history.push("/Service");
        break;
      case "privacy":
        history.push("/Privacy");
        break;
      case "contact":
        history.push("/Contact");
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="footer-row">
          <div className="footer">
            <div className="row1">
              <div className="left">
                <h1>NEWSLETTER SUBSCRIPTION</h1>
                <p>
                  Sign up for Data Core Seeds  updates to receive information about
                  new plants, special offers and discounts. We only send a few
                  emails a year.
                </p>
              </div>
              <div className="right">
                <input
                  type="text"
                  placeholder="Enter your email address here"
                />
                <div className="btn">SUBSCRIBE!</div>
              </div>
            </div>
            <div className="row2">
              <div className="col">
                <h1>MAIN MENU</h1>
                <p onClick={() => this.routePage("home")}>Home</p>
                <p onClick={() => this.routePage("about")}>About Us</p>
              </div>
              <div className="col">
                <h1>POLICIES</h1>
                <p onClick={() => this.routePage("contact")}>Contact Us</p>
                <p onClick={() => this.routePage("ship")}>Shipping Policy</p>
                <p onClick={() => this.routePage("refund")}>Refund Policy</p>
                <p onClick={() => this.routePage("service")}>
                  Terms of Service
                </p>
                <p onClick={() => this.routePage("privacy")}>Privacy Policy</p>
              </div>
              <div className="col">
                <h1>SHIPPING & RETURNS</h1>
                <p>
                  Free Delivery when you spend £5 or more. Otherwise it's just
                  £1 for standard postage.
                </p>
                <p>
                  Our standard delivery is via Royal Mail 2nd Class. We also
                  offer great value express delivery options on the checkout
                  page.{" "}
                </p>
                <p>Free returns within 90 days with a money back guarantee.</p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FooterBar;
